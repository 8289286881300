<template>
  <div>
    <b-card
      overlay
      img-src="https://ndsi.rtarf.mi.th/dev201709/images/events/s64-07-01-%E0%B8%9C%E0%B8%B9%E0%B9%89%E0%B8%9A%E0%B8%B1%E0%B8%87%E0%B8%84%E0%B8%B1%E0%B8%9A%E0%B8%9A%E0%B8%B1%E0%B8%8D%E0%B8%8A%E0%B8%B2%E0%B8%96%E0%B8%A7%E0%B8%B2%E0%B8%A2%E0%B8%9E%E0%B8%A3%E0%B8%B0%E0%B8%9E%E0%B8%A3%20%E0%B8%A3.10/ppppp%20(Small).jpg"
      text-variant="white"
      footer-bg-variant="white"
      footer-text-variant="dark"
    >
      <!-- <div class="text-right">
        <b-button href="/reason-detail" variant="primary" style="padding: 2px;">
          <p class="m-0" style="font-size: 0.8rem;">ลงทะเบียน</p>
        </b-button>
      </div> -->
      <template #footer>
        <p style="color: #000;">
          <b> พาดหัวข่าวประชาสัมพันธ์ </b>
        </p>
        <p style="color: #000; font-size: 0.7rem">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam
        </p>
        <!-- <div>
          <button
            class="btn btn-lg btn-primary btn-block form-group"
            style="color: #fff;"
          >
            <span>
              <b>อ่านต่อ</b>
            </span>
          </button>
        </div> -->
      </template>
    </b-card>
  </div>
</template>

<script>
export default {
  methods: {
    async reasonDetail() {
      location.href = "/reason-detail";
    },
  },
};
</script>
