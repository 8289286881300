<template>
  <page-content>
    <!-- <img src="@/assets/img/e-learn_banner.png" style="width: 100%" /> -->
    <img
      src="@/assets/so_banner.png"
      class="mobile"
      style="width: 100%; height: 600px; margin-top: -80px;"
    />
    <!-- <img src="@/assets/mock/header.png" style="    width: 100%;" /> -->
    <!-- <page-title
      heading="ยินดีต้อนรับเข้าสู่เว็บไซต์ E-LEARNING D.A.R.E. Thailand"
    ></page-title> -->

    <!-- <banner-image-home></banner-image-home> -->
    <div
      :style="{
        backgroundImage: `url(${require('@/assets/backgroup_home.png')})`,
      }"
    >
      <br />
      <br />
      <div style="padding: 30px;">
        <div class="row mb-4">
          <div class="col-4">
            <p
              class="mt-8"
              style="padding-left: 10px; font-size: 1.3rem; color: #fff;"
            >
              <b>หลักสูตรการเรียน E-Learning</b>
            </p>
          </div>
          <div class="col-8">
            <b-form-group class="px-2">
              <b-input-group class="input-group-seamless">
                <b-form-input placeholder="ค้นหาหลักสูตร"></b-form-input>

                <b-input-group-text slot="prepend">
                  <font-awesome-icon class="mx-auto" icon="search" />
                </b-input-group-text>

                <!-- <b-input-group-text  slot="append">
              <a href="javascript:void(0)" >
                <font-awesome-icon class="mx-auto" icon="window-close" />
              </a>
            </b-input-group-text> -->
              </b-input-group>
            </b-form-group>
          </div>
        </div>
        <div
          class="row mb-4"
          style="padding-left: 20px; padding-right: 20px; margin-top: -30px"
        >
          <div class="col-3">
            <a>
              <p
                class="mt-4 text-center"
                align-items-center
                style="
                text-decoration: underline;
                color: #fff;
                text-decoration-color: #fff;
                text-decoration-thickness: 3px;
              "
                @click="buyItem(price)"
              >
                <b>หลักสูตรยอดนิยม</b>
              </p>
            </a>
          </div>
          <div class="col-3">
            <p class="mt-4 text-center" style="padding-left: 10px; color: #fff">
              หลักสูตรใหม่
            </p>
          </div>
          <div class="col-3">
            <p class="mt-4 text-center" style="padding-left: 10px; color: #fff">
              หลักสูตรสำหรับเด็ก
            </p>
          </div>
          <div class="col-3">
            <p class="mt-4 text-center" style="padding-left: 10px; color: #fff">
              เอกสารอื่น ๆ
            </p>
          </div>
        </div>
      </div>
      <!-- <carousel-3d :controls-visible="true"  :clickable="false"> -->
      <carousel-3d :controls-visible="true"  :inverse-scaling="5">
        <slide :index="0" style="border-radius: 10px; border: 1px solid #fff;">
          <figure>
            <img src="@/assets/ThinkTank.jpg" style="height: 220px;" />
            <figcaption>
              <div class="text-center">
                <!-- <p style="padding-top: 10px;">หลักสูตรการเรียนช่าง</p> -->
                <button
                  @click="reasonDetail()"
                  class="btn btn-lg  btn-block form-group"
                  style="color: #fff;"
                >
                  <span>
                    <b>ดูรายละเอียดหลักสูตร</b>
                  </span>
                </button>
              </div>
            </figcaption>
          </figure>
        </slide>
        <slide :index="1" style="border-radius: 10px; border: 1px solid #fff;">
          <figure>
            <img src="@/assets/img/eng1.jpeg" style="height: 220px;" />
            <figcaption>
              <div class="text-center">
                <!-- <p style="padding-top: 10px;">หลักสูตรการเรียนช่าง</p> -->
                <button
                  @click="reasonDetail()"
                  class="btn btn-lg  btn-block form-group"
                  style="color: #fff;"
                >
                  <span>
                    <b>ดูรายละเอียดหลักสูตร</b>
                  </span>
                </button>
              </div>
            </figcaption>
          </figure>
        </slide>
        <slide :index="2" style="border-radius: 10px; border: 1px solid #fff;">
          <figure>
            <img src="@/assets/ThinkTank.jpg" style="height: 220px;" />
            <figcaption>
              <div class="text-center">
                <!-- <p style="padding-top: 10px;">หลักสูตรการเรียนช่าง</p> -->
                <button
                  @click="reasonDetail()"
                  class="btn btn-lg  btn-block form-group"
                  style="color: #fff;"
                >
                  <span>
                    <b>ดูรายละเอียดหลักสูตร</b>
                  </span>
                </button>
              </div>
            </figcaption>
          </figure>
        </slide>
        <slide :index="3" style="border-radius: 10px; border: 1px solid #fff;">
          <figure>
            <img src="@/assets/ThinkTank.jpg" style="height: 220px;" />
            <figcaption>
              <div class="text-center">
                <!-- <p style="padding-top: 10px;">หลักสูตรการเรียนช่าง</p> -->
                <button
                  @click="reasonDetail()"
                  class="btn btn-lg  btn-block form-group"
                  style="color: #fff;"
                >
                  <span>
                    <b>ดูรายละเอียดหลักสูตร</b>
                  </span>
                </button>
              </div>
            </figcaption>
          </figure>
        </slide>
        <slide :index="4" style="border-radius: 10px; border: 1px solid #fff;">
          <figure>
            <img src="@/assets/ThinkTank.jpg" style="height: 220px;" />
            <figcaption>
              <div class="text-center">
                <!-- <p style="padding-top: 10px;">หลักสูตรการเรียนช่าง</p> -->
                <button
                  @click="reasonDetail()"
                  class="btn btn-lg  btn-block form-group"
                  style="color: #fff;"
                >
                  <span>
                    <b>ดูรายละเอียดหลักสูตร</b>
                  </span>
                </button>
              </div>
            </figcaption>
          </figure>
        </slide>
        <slide :index="5" style="border-radius: 10px; border: 1px solid #fff;">
          <figure>
            <img src="@/assets/ThinkTank.jpg" style="height: 220px;" />
            <figcaption>
              <div class="text-center">
                <!-- <p style="padding-top: 10px;">หลักสูตรการเรียนช่าง</p> -->
                <button
                  @click="reasonDetail()"
                  class="btn btn-lg  btn-block form-group"
                  style="color: #fff;"
                >
                  <span>
                    <b>ดูรายละเอียดหลักสูตร</b>
                  </span>
                </button>
              </div>
            </figcaption>
          </figure>
        </slide>
        <slide :index="6" style="border-radius: 10px; border: 1px solid #fff;" >
          <figure>
            <img src="@/assets/ThinkTank.jpg" style="height: 220px;" />
            <figcaption>
              <div class="text-center">
                <!-- <p style="padding-top: 10px;">หลักสูตรการเรียนช่าง</p> -->
                <button
                  @click="reasonDetail()"
                  class="btn btn-lg  btn-block form-group"
                  style="color: #fff;"
                >
                  <span>
                    <b>ดูรายละเอียดหลักสูตร</b>
                  </span>
                </button>
              </div>
            </figcaption>
          </figure>
        </slide>
      </carousel-3d>
      <br />
      <v-divider width="80%" style="padding: 20px"></v-divider>
      <div style="padding-left: 60px; padding-right: 60px;">
        <h3 class="mt-4" style="color: #627C86">ข่าวประชาสัมพันธ์</h3>
        <br />
        <v-row>
          <v-col cols="12" lg="3">
            <news-card style="width: 100%"> </news-card>
          </v-col>
          <v-col cols="12" lg="3">
            <news-card style="width: 100%"> </news-card>
          </v-col>
          <v-col cols="12" lg="3">
            <news-card style="width: 100%"> </news-card>
          </v-col>
          <v-col cols="12" lg="3">
            <news-card style="width: 100%"> </news-card>
          </v-col>
        </v-row>
      </div>
      <br />
    </div>
  </page-content>
</template>

<script>
import PageContent from "../components/layout/PageContent";
// import PageTitle from "../components/layout/PageTitle";
// import BannerImageHome from "../components/carousel/BannerImageHome";
// import News from "../components/carousel/News";
// import Reason from "../components/carousel/Reason";

// import ReasonCard from "../components/card/ReasonCard";
import NewsCard from "../components/card/NewsCard";
import { Carousel3d, Slide } from "vue-carousel-3d";

export default {
  components: {
    PageContent,
    // PageTitle,
    // BannerImageHome,
    // News,
    // ReasonCard,
    NewsCard,
    Carousel3d,
    Slide,
    // Reason,
  },

  data() {
    return {};
  },
  methods: {
    async reasonDetail() {
      location.href = "/reason-detail";
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  &___width-override {
    max-width: 1440px !important;

    @media only screen and (max-width: 1660px) and (min-width: 1420px) {
      max-width: 1140px !important;
      .mobile {
        display: none;
      }
    }
    @media only screen and (max-width: 1419px) and (min-width: 1140px) {
      max-width: 960px !important;
      .mobile {
        display: none;
      }
    }

    @media only screen and (max-width: 1139px) and (min-width: 770px) {
      max-width: 760px !important;
      .desktop {
        display: none;
      }
    }

    @media (max-width: 760px) {
      max-width: 360px !important;
      .desktop {
        display: none;
      }
    }
  }
}
</style>
